import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import img_gatsby from "assets/images/caim-logo.png";
import Container from "components/Container";

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
          title
        }
      }
    }
  `);
  return (
    <header>
      <Container>
        <p><AniLink cover
              to="/"
              bg="#E0B668"
              duration={0.7}
              className="header-link">
          <img className="logo" src={img_gatsby} alt="Caim" />
        </AniLink></p>
        {/* uncomment the line below to add your name in the header */}
        {/* <p>{data.site.siteMetadata.author}</p> */}
        <ul>
          {/* <li>
            <AniLink
              cover
              to="/"
              bg="#6666ff"
              duration={0.7}
              className="header-link"
            >
              Home
            </AniLink>
          </li> */}
          <li>
            <AniLink
              cover
              direction="right"
              to="/about/"
              bg="#E0B668"
              duration={0.7}
              className="header-link"
            >
              About
            </AniLink>
          </li>
          <li>
            <a
              cover
              direction="right"
              href="https://caimshop.com/"
              bg="#E0B668"
              duration={0.7}
              className="header-link"
            >
              Shop
            </a>
          </li>
          {/* <li>
            <AniLink
              cover
              to="/services/"
              bg="#E0B668"
              duration={0.7}
              className="header-link"
            >
              Services
            </AniLink>
          </li> */}
          <li>
            <AniLink
              cover
              direction="right"
              to="/contact/"
              bg="#E0B668"
              duration={0.7}
              className="header-link"
            >
              Contact
            </AniLink>
          </li>
        </ul>
      </Container>
    </header>
  );
};

export default Header;
