import React from "react";
import { graphql, useStaticQuery } from "gatsby";

//images
import mail from "assets/images/SVG/mail.svg";
import tiktok from "assets/images/SVG/tiktokme.svg";
import insta from "assets/images/SVG/instagram.svg";
import phone from "assets/images/SVG/phone.svg";

import Container from "components/Container";

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
        }
      }
    }
  `);
  return (
    <footer>
      <Container>
        {/* add author's name in footer by uncommenting the following line*/}
        {/* <p>Made by by {data.site.siteMetadata.author} using Gatsby</p> */}
        <p className="footer-text">
          Made with{" "}
          <span id="heart-container" className="heart-container" role="img" aria-label="heart">
            ❤️
          </span>{" "}
          by <a
            href="https://www.shaneo.ie"
            target="_blank"
            rel="noopener noreferrer"
          >shaneo</a>.
        </p>
        <div className="footer__icons">
          <a href="tel:087-6604646" rel="noopener noreferrer">
            <svg className="footer__icons-item">
              <use xlinkHref={`#${phone.id}`} />
            </svg>
          </a>
          <a href="mailto:caimskinandwellness@gmail.com" rel="noopener noreferrer">
            <svg className="footer__icons-item">
              <use xlinkHref={`#${mail.id}`} />
            </svg>
          </a>
          <a href="https://www.tiktok.com/@aoifeeo" rel="noopener noreferrer">
            <svg className="footer__icons-item">
              <use xlinkHref={`#${tiktok.id}`} />
            </svg>
          </a>
          <a href="https://www.instagram.com/caimskinandwellness/"
            target="_blank"
            rel="noopener noreferrer">
            <svg className="footer__icons-item">
              <use xlinkHref={`#${insta.id}`} />
            </svg>
          </a>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
